import config from "@/config";

import { get, patch, post, del } from "@/requests"

export function getAdvicesData() {    
    return get(`${config.BACK_IP}/advice`)
}

export async function createNewAdvice(title, description, idPathology) {
    return post(`${config.BACK_IP}/advice`, {title, description, id_pathology: idPathology})
}

export async function updateAdvice(title, description, id) {
    return patch(`${config.BACK_IP}/advice/${id}`, {title, description, id})
}

export function deleteAdvice(id) {
    return del(`${config.BACK_IP}/advice/${id}`)
}