import config from "@/config";
import { get, del, post, patch } from "@/requests"

export async function getPathologiesData() {
    return get(`${config.BACK_IP}/pathologies`)
}

export async function getPatientPathologies(id) {
    return get(`${config.BACK_IP}/patient/${id}/pathologies`)
}

export async function createNewPathology(newPathology) {
    return post(`${config.BACK_IP}/pathologies`, newPathology)
}

export async function updatePathology(newPathology, id) {
    return patch(`${config.BACK_IP}/pathologies/${id}`, newPathology)
}

export function deletePathology(id) {
    return del(`${config.BACK_IP}/pathologies/${id}`)
}

