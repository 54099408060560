<!-- TODO - DESARROLLAR LAS RULES -->
<!-- 
    -> Descripción: Componente para crear formularios de manera dinámica, mediante un objeto JSON que se le pasa desde el componente padre.
    -> Parámetros de entrada:
        - formContent: Objeto JSON con la estructura del formulario.
        - fields: Objeto JSON con los campos del formulario. Estos campos son los que se envían al componente padre al realizar el emit mediante el botón correspondiente. El formato del fichero será el siguiente:
        formContent: { // Objeto con los datos del formulario
            buttons: [ // Array que contiene los botones que se crearán en el formulario para realizar las acciones oportunas
                {
                    textButton: 'Volver', // Texto del botón
                    color: 'secondary', // Color del botón
                    event: 'back', // Evento que se ejecutará al pulsar el botón
                    style: 'margin: 10px', // Estilos del botón, separados por punto y coma (;)
                },
            ],
            rows: [ // Array que contiene las filas que se formarán en el formulario
                { // Ejemplo de fila con una columna
                    style: '', // Estilos de la fila, separados por punto y coma (;)
                    class: '', // Clases de la fila, separadas por espacio ()
                    content: [ // Array que contiene las columnas que estarán dentro de la fila padre. Pueden contener varios elementos
                        {
                            type: 'element', // Tipo del elemento que se creará en la columna. Puede ser un elemento normal (element) o un grid (grid)
                            rule: [], // TODO - Desarrollar las rules
                            style: '', // Estilos de la columna, separados por punto y coma (;)
                            class: '', // Clases de la columna, separadas por espacio ()
                            content: { // Objeto con los datos del elemento que se creará en la columna. 
                                // Si el tipo es grid, este objeto será el objeto JSON (con los buttons y las rows) que se le pasará al componente AbstractForm recursivamente, repitiendose el proceso de creación de formularios. 
                                // Si el tipo es element, será un objeto con los elementos que necesite el componente a crear.
                                type: 'input', // Tipo del elemento que se creará en la columna. Puede ser un input (input), un select (select), un date (date) o un password (password)
                                value: '', // Valor del elemento que se creará en la columna. Las modificaciones de este valor se actualizarán en el objeto fields, que se le pasa al componente padre al realizar el emit mediante el botón correspondiente
                                key: 'name', // Clave del elemento que se creará en la columna. Esta clave será la que se utilizará para identificar cada elemento y diferenciarlo del resto a la hora de hacer referencia a él.
                                label: 'Nombre', // Representacion del valor del elemento en el campo correspondiente del formulario, es decir, lo que se mostrará en el formulario correspondiente al valor introducido
                                maxLength: 30, // Longitud máxima del elemento
                            },
                        },
                    ]
                },
                { // Ejemplo de fila con dos columnas
                    style: '',
                    class: '',
                    content: [ // Las dos filas del ejemplo
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'password',
                                value: '',
                                key: 'password',
                                label: 'Contraseña',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'password',
                                value: '',
                                key: 'repeatPassword',
                                label: 'Confirmar contraseña',
                                maxLength: 30,
                            },
                        },
                    ]
                },
            ],
        },
    -> Parámetros de salida:
        - emit-methods: Método que se ejecuta al pulsar el botón correspondiente del formulario. Se le pasa el evento y los campos del formulario con el contenido de ese momento.
            Esto se realizará por cada botón que se le haya pasado a este componente desde el padre, mediante el contenido del elemento formContent.buttons
-->

<template>
    <div class="form">
        <div v-for="(row, rowIndex) in localFormContent.rows" :key="rowIndex">
            <CRow :class=row.class :style="row.style">
                <CCol v-for="(col, colIndex) in row.content" :key="colIndex" :class="col.class" :style="col.style">
                    <div v-if="col.type !== 'grid'">
                        <div v-for="(element, elementIndex) in col" :key="elementIndex">
                            <CInput v-if="element.type === 'input'" :id="element.key" v-model="fields[element.key]"
                                type="text" :label="element.label" horizontal :maxlength="element.maxLength"
                                :style="element.styles" :readonly="!canEdit && isAdmin"/>
                            <CInput v-if="element.type === 'date'" :id="element.key" v-model="fields[element.key]"
                                type="date" :label="element.label" horizontal :readonly="!canEdit && isAdmin"/>
                            <CInput v-if="element.type === 'password'" :id="element.key" v-model="fields[element.key]"
                                type="password" :label="element.label" horizontal :readonly="!canEdit"/>
                            <CInput v-if="element.type === 'file'" :id="element.key" v-model="fields[element.key]" accept=".jpg, .png, .webp, .svg" 
                                type="file" :label="element.label" horizontal :readonly="!canEdit && isAdmin" />
                            <CTextarea v-if="element.type === 'textArea'" :id="element.key" v-model="fields[element.key]" 
                                :label="element.label" horizontal :maxlength="element.maxLength" :readonly="!canEdit && isAdmin"/>
                            <CRow v-if="element.type === 'select'">
                                <CCol class="col-sm-3 align-middle" style="vertical-align: auto;">
                                    <h6>{{ element.label }}</h6>
                                </CCol>
                                <CCol class="col-sm-9 select-column">
                                    <select :id="element.key" v-model="fields[element.key]" :disabled="!canEdit && isAdmin">
                                        <option v-for="(option, optionIndex) in element.options" :key="optionIndex"
                                            :value="option.value">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </CCol>
                            </CRow>

                        </div>
                    </div>
                    <div v-if="col.type === 'grid'">
                        <AbstractForm :formContent="col.content" />
                    </div>
                </CCol>
            </CRow>
        </div>
        <div class="d-flex justify-content-center">
            <div v-for="(button, buttonIndex) in formContent.buttons" :key="buttonIndex">
                <CButton v-if="button.active != undefined ? button.active : true" :color="button.color" :class=button.class :style="button.style" @click="onEmit(button)">
                    {{ button.textButton }}
                </CButton>
            </div>
        </div>
    </div>
</template>

<script>
import AbstractForm from '@/components/Forms/AbstractForm.vue';

export default {
    name: 'AbstractForm',
    components: {
        AbstractForm,
    },
    data: () => ({
        localFormContent: null,
        isAdmin: null,
    }),
    props: {
        formContent: {
            type: Object,
            required: true,
        },
        fields: {
            type: Object,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.isAdmin = this.$store.state.isAdmin
        this.localFormContent = this.formContent;
    },
    methods: {
        onEmit(button) {
            this.$emit('emit-methods', button.event, this.fields);
        },
    }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .col {
    flex-basis: initial;
  }
  .select-column{
    margin-bottom: 5px;
    padding: 0 14px;
  }
}
</style>