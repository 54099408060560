<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="adviceFields" />
    </CCardBody>
  </CCard>
</template>

<script>
import {getPathologiesData} from '@/services/pathologies';
import {createNewAdvice} from '@/services/advices';
import AbstractForm from '@/components/Forms/AbstractForm.vue';

export default {
  name: "addAdvice",
  components: { AbstractForm },
  data() {
    return {
      title: "Nuevo consejo",
      id: null,
      adviceFields: {
        title: null,
        description: null,
        id_pathology: null
      },
      pathologies: [],
      formContent: {
        buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true
                },
                {
                    textButton: 'Crear',
                    color: 'success',
                    event: 'add',
                    style: 'margin: 10px',
                    active: this.$store.state.isAdmin
                },
          ],
          rows: [
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'input',
                   value: '',
                   key: 'title',
                   label: 'Título del consejo',
                   maxLength: 30,
                  },
                },
              ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'textArea',
                   value: '',
                   key: 'description',
                   label: 'Descripción del consejo',
                  maxLength: 1000,
                  },
                },
              ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'select',
                   value: '',
                   key: 'id_pathology',
                   label: 'Patología',
                   options: this.pathologies,
                  },
                },
              ]
            },
          ]
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.receiveData();
  },
  methods: {
    receiveData() {
      this.getPathologies().then(() => {
        this.formContent.rows[2].content[0].content.options = this.pathologies;
      });
    },
    onEmitMethods(event, dataAdvice) {
        switch (event) {
            case 'add': this.addAdvice(dataAdvice); break;
            case 'back': this.back(); break;
            default: break;
        }
    },
    showAlertError(msg) {
       this.$store.state.errorAlert = { status: true, msg: msg.toString() }
    },
    /**
     * Método para validar los inputs del formulario
    */
    checkInputs(dataAdvice) {
        const validationRules = [
            { field: 'title', message: 'Introduzca el nombre de la patología (máximo de 30 caracteres).', maxLength: 30 },
            { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },
            { field: 'id_pathology', message: 'Seleccione una patología.', value: '' },
        ];
        for (const rule of validationRules) {
            const value = dataAdvice[rule.field];
            if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength)) {
                this.showAlertError(rule.message);
                return false;
            }
        }
            return true;
    },
    /**
     * Función asociada al boton de crear, para crear un consejo.
     */
    addAdvice(dataAdvice) {
      if (this.checkInputs(dataAdvice) == false) return

        createNewAdvice(dataAdvice.title, dataAdvice.description, dataAdvice.id_pathology).then(() => {
          this.$router.push("/advice");
        })
    },
    /**
     * Función en la que se obtiene y se guarda la información
     * de las patologias en la variable patologías.
     * 
     * @returns {object[]} Un arreglo con las patologias obtenidas
     */
     async getPathologies() {
      try {
        const result = await getPathologiesData();
        const data = result.map(pathology => ({
          name: pathology.name,
          value: pathology.id,
        }));
        this.pathologies = data;
      } catch (error) {
        this.$store.state.errorAlert = { status: true, msg: error.toString() };
      }
    },
    /**
     * Función para mostrar el alert durante 10 segundos.
     */
    showFailureParameters() {
      this.alertCounterParameters = 10;
    },
    /**
     * Función para volver atrás
     */
    back() {
      window.history.back();
    },
  }
};
</script>

<style>
textarea.form-control {
  height: 200px;
}
</style>